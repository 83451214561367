<template>
  <basic-page-card
    pageName="Report Writer"
    pageType="Select Report"
    iconClass="fa fa-bar-chart fa-lg"
  >
    <b-row class="search-container--institution">
      <b-col sm="12">
        <div class="search-container--bar">
          <b-input-group>
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-search fa-lg"></i>
              </span>
            </div>
            <b-form class="search-form">
              <b-form-input
                v-focus
                autocomplete="off"
                id="prependedInput"
                class="form-control no-focus-highlt"
                size="lg"
                type="text"
                placeholder="Type Report Name to Select"
                @keyup.native="search"
                v-model="searchText"
              >
              </b-form-input>
            </b-form>
          </b-input-group>
        </div>
        <div class="search-container--result std-border">
          <div>
            <content-placeholders :rounded="true" v-if="loading">
              <content-placeholders-text
                :lines="15"
              ></content-placeholders-text>
            </content-placeholders>
            <div class="text-left" v-else>
              <b-list-group v-if="!customReports.length" class="text-center">
                <b-list-group-item
                  ><i>No Custom Reports found ... </i></b-list-group-item
                >
              </b-list-group>
              <b-list-group v-else>
                <div v-for="report in filteredReports" :key="report.id">
                  <b-list-group-item
                    @click="setReport(report)"
                    :class="[
                      {
                        selected: selectedReport.id === report.id
                      },
                      'p-2 pl-4 d-flex align-items-baseline'
                    ]"
                    :ref="'report_' + report.id"
                  >
                    <span :id="`cr-${report.id}`">
                      {{ report.name }}
                      <span class="text-muted" v-if="isFinApiEnterpriseUser"
                        >[{{ report.id }}]
                      </span>
                      <span
                        class="text-muted"
                        v-if="report.group_ids && report.group_ids.length"
                      >
                        <b-badge pill variant="secondary" class="px-1"
                          >Shared By user: {{ report.user_id }}
                        </b-badge>
                      </span>
                    </span>
                    <span
                      class=" pl-1 favorite__star__selected"
                      v-if="report.category === 'key_metrics'"
                      ><b> &#x2605;</b></span
                    >
                    <b-popover
                      :target="`cr-${report.id}`"
                      triggers="hover"
                      placement="left"
                      v-if="report.description"
                    >
                      {{ report.description }}
                    </b-popover>
                  </b-list-group-item>
                </div>
              </b-list-group>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="text-center pt-1">
      <b-col md="12">
        <b-button
          size=""
          variant="primary"
          @click="createReport()"
          class="fw-btn mt-3 mx-3"
          >Create Report</b-button
        >
        <router-link
          tag="button"
          :to="{ path: 'custom_report' }"
          class="btn btn-primary fw-btn mt-3 mx-3"
          :disabled="isDisabled"
        >
          Edit Report
        </router-link>
      </b-col>
    </b-row>
  </basic-page-card>
</template>

<script>
// global
import { mapState, mapGetters, mapMutations } from 'vuex'
//api
import selfServeReportsAPI from '@/api/finapps/self_serve_reports'
// ui components
import BasicPageCard from '@/shared/components/layouts/BasicPageCard'

export default {
  name: 'UserCustomReports',
  components: {
    BasicPageCard
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.getUserReports()
    })
  },
  data() {
    return {
      loading: false,
      searchText: '',
      customReports: [],
      filteredReports: []
    }
  },
  computed: {
    ...mapState('ReportWriter', {
      selectedReport: state => state.selectedReport,
      reportMode: state => state.reportMode
    }),
    ...mapGetters('Ability', ['isFinApiEnterpriseUser']),
    isDisabled() {
      return !this.selectedReport || this.selectedReport.id === null
    }
  },
  methods: {
    ...mapMutations('ReportWriter', ['setCustomReport', 'clearSelectedReport']),
    getUserReports() {
      this.loading = true
      selfServeReportsAPI.userReports().then(res => {
        this.customReports = res.custom_reports
        this.filteredReports = res.custom_reports
        this.loading = false
      })
    },
    search: function(event) {
      let text = event.target.value.toLowerCase()
      let emptyText = this.text === ''

      this.filteredReports = emptyText
        ? this.customReports
        : this.customReports.filter(
            report => report.name.toLowerCase().indexOf(text) >= 0
          )
    },
    setReport(report) {
      this.searchText = report.name
      this.setCustomReport(report)
    },
    createReport() {
      this.clearSelectedReport()
      this.$router.push('custom_report')
    }
  }
}
</script>

<style lang="scss" scoped>
.favorite__star__selected {
  color: #a7ca77;
  display: inline-block;
  padding: 3px;
  vertical-align: middle;
  line-height: 1;
  font-size: 1rem;
  cursor: pointer;
  -webkit-transition: color 0.2s ease-out;
  transition: color 0.2s ease-out;
  -webkit-text-stroke: 1px grayscale($color: #686262fb);
}
</style>
